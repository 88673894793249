<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :per-page="10000"
      show-date
      search-label="search"
      vertical-scroll
      :filter-names="filterNames"
      :export-options="['excel', 'csv', 'pdf']"
      @isExport="downloadFiles"
      @filterOption="onFiltersChange"
    >
      <template v-slot:services="{ item }">
        <div class="text-capitalize">
          {{ item.services }}
        </div>
      </template>

      <template #addFilters>
        <v-card class="allVehicleSelect">
          <v-autocomplete
            v-model="customFilters.vehicle_ids"
            dense
            filled
            :loading="isVehicle"
            :items="vehicleList"
            item-value="id"
            item-text="vehicle_name"
            label="Select Vehicle"
            multiple
            @change="fetchData"
          >
            <template v-slot:selection="data">
              <span
                v-if="data.index === 0"
                class="selected"
              >
                <span>{{ data.item.vehicle_name }}</span>
              </span>
              <span
                v-if="data.index === 1"
                class="grey--text text-caption"
              >
                (+{{ customFilters?.vehicle_ids?.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-card>
      </template>
      <template v-slot:description>
        <section v-if="list.length > 0">
          <div>
            <fuel-chart
              :loading="isLoading"
              :items="list"
            />
          </div>
          <div class="fuel-cards">
            <dashboard-cards
              :is-loading="isLoading"
              :items="dashboardTags"
            />
          </div>
        </section>
      </template>

      <!-- Action Modals -->
      <!-- <template #actionModals> </template> -->
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { debounce } from "lodash";
import { mapGetters } from "vuex";

// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    FuelChart: () => import("./utils/FuelChart.vue"),
    dashboardCards: () =>
      import("@/components/sections/DashboardSliderCards.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      permissions: {
        // view: "fuel-tanks.view",
        // create: "fuel-tanks.create",
        // edit: "fuel-tanks.edit",
        // delete: "fuel-tanks.delete",
      },
      customFilters: {},
      //   isMounted: false,
      filters: {},
      subFilters: {},
      //   terminal_id: null,

      //
      groupId: "vehicle_fuel_dashboard",
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "fuelDashboard/getLoading",
      meta: "fuelDashboard/getMeta",
      dashboardData: "fuelDashboard/getList",
      isVehicle: "vehicles/getIsLoadingVehicles",
      vehicleList: "vehicles/getVehiclesList",
      //
      tenantList: "tenantsManagement/getTenantOptions",
    }),
    filterNames() {
      return [];
    },
    dashboardTags() {
      const arrData = this.dashboardData?.aggregated_data[0];

      return [
        {
          myIcon: "total-filling-cost",
          name: "total_fuel_cost",
          value: arrData.total_fuel_cost,
          unit: this.$options.filters.currencyFormat(
            this.$t("units.fuel_cost"),
            this.authUser
          ),
        },
        // {
        //   myIcon: "total-volume",
        //   name: "total_volume",
        //   value: arrData.total_volume,
        //   unit: "(L)",
        // },
        {
          // myIcon: "destination-icon",
          myIcon: "average-fuel-economy",
          name: "avg_fuel_economy",
          value: arrData.avg_fuel_economy,
          unit: "(KM/L)",
        },
        {
          myIcon: "total-fuel-consumed",
          name: "total_fuel_consumed",
          value: arrData.total_fuel_consumed,
          unit: "(L)",
        },
        {
          myIcon: "total-fillings-volume",
          name: "total_filling_volume",
          value: arrData.total_filling_volume,
          unit: "(L)",
        },
        // {
        //   myIcon: "total-filling-count",
        //   name: "total_filling_count",
        //   value: arrData.total_filling_count,
        //   unit: "",
        // },
        {
          myIcon: "total-drains-volume",
          name: "total_drains_volume",
          value: arrData.total_drains_volume,
          unit: "(L)",
        },
        {
          myIcon: "total-drains-count",
          name: "total_drains",
          value: arrData.total_drains_count,
          unit: "(Count)",
        },
      ];
    },
    list() {
      return this.dashboardData?.all_data || [];
    },
    _headers() {
      const headers = [
        {
          text: this.$t("date"),
          align: "left",
          sortable: true,
          value: "date_only",
        },
        {
          text: this.$t("registration_no"),
          align: "left",
          sortable: false,
          value: "registration_no",
        },
        {
          text: this.$options.filters.currencyFormat(
            this.$t("fuel_cost"),
            this.authUser
          ),
          align: "left",
          sortable: false,
          value: "fuel_cost",
          showAdmin: true,
        },

        {
          text: this.$t("distance_travelled"),
          align: "left",
          sortable: false,
          value: "mileage",
        },
        {
          text: this.$t("volume_at_day_start"),
          align: "left",
          sortable: false,
          value: "volume_at_day_start",
        },
        {
          text: this.$t("refuel"),
          align: "left",
          sortable: false,
          value: "refuel",
        },
        {
          text: this.$t("volume_at_day_end"),
          align: "left",
          sortable: false,
          value: "volume_at_day_end",
        },
        {
          text: this.$t("fuel_consumed"),
          align: "left",
          sortable: false,
          value: "consumed_fuel",
        },
        {
          text: this.$t("fuel_economy"),
          align: "left",
          sortable: false,
          value: "fuel_economy",
        },
        {
          text: this.$t("drains_count"),
          align: "left",
          sortable: false,
          value: "drains_count",
        },
        {
          text: this.$t("drains_volume"),
          align: "left",
          sortable: false,
          value: "drains_volume",
        },
      ];
      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    "options.tenant_id": {
      handler: function (val) {
        if (val) {
          this.getVehicles(val);
        } else {
          this.customFilters = {};
          this.$store.commit("vehicles/SET_VEHICLES", []);
        }
      },
    },
    "options.fleetId": {
      handler: function (v) {
        if (v) {
          const t = this.tenantList.find((r) => r.id == v);
          const tid = t.is_independent_fleet ? t.id : t.parent_tenant_ids[0];
          this.getVehicles(tid);
        } else {
          this.customFilters = {};
          this.$store.commit("vehicles/SET_VEHICLES", []);
        }
      },
    },
    options() {
      this.fetchData();
    },
    customFilters() {
      this.fetchData();
    },
  },
  async mounted() {
    if (!this.authUser.isAdmin) {
      this.getVehicles(this.authUser.tenant_id);
    }
  },

  beforeDestroy() {
    const params = {
      name: "vehicle_fuel_dashboard",
      filters: { ...this.options, ...this.customFilters },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
    this.$store.commit("fuelDashboard/SET_LIST", []);
  },
  methods: {
    fetchData: debounce(async function () {
      if (this.customFilters?.vehicle_ids?.length > 0) {
        const ids = this.customFilters.vehicle_ids;
        const imeis = this.vehicleList
          .filter((r) => ids.includes(r.id))
          .map((r) => r.iot_device_imei);

        //  // Cancel any ongoing requests in the same group
        this.cancelRequestGroup();

        // Track the new request
        const controller = this.trackRequest();

        const params = {
          ...this.options,
          ...this.customFilters,
          imeis,
          signal: controller.signal,
        };
        await this.$store.dispatch("fuelDashboard/list", params).then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
      }
    }, 1000),

    //
    async getVehicles(val) {
      const params = { tenant_id: val };
      await this.$store
        .dispatch("vehicles/vehicleDropDown", params)
        .then((res) => {
          // res.forEach((element) => {
          this.customFilters.vehicle_ids = res.map((r) => r.id);
          // });
        });
    },

    downloadFiles(val) {
      const d = this.options.date.join("--");
      const fileName = `Vehicle-Fuel-Dashboard ${d}`;

      if (val === "csv") {
        this.downloadCSV(this._headers, this.list, fileName);
      } else if (val === "excel") {
        this.downloadExcel(this._headers, this.list, fileName);
      } else if (val === "pdf") {
        this.downloadPDF(this._headers, this.list, fileName);
      }
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .fuel-cards .sectionOneBoxWrap .insideItemBoxWrap .itemBoxes .iconBox span .lg
  width: 2.3rem
:deep .fuel-cards .sectionOneBoxWrap .insideItemBoxWrap .itemBoxes .iconBox span .total-filling-cost
  width: 4.3rem
</style>
